import request from '@/http/request'
import wayCode from "@/utils/wayCode";
import channelUserIdUtil from "@/utils/channelUserId";
import config from '@/config'

// 聚合支付统一下单
export function unifiedOrder (data) {
  return request.request({
    url: '/api/pay/unifiedOrder',
    method: 'POST',
    data: data
  })
}

/** 获取url **/
export function getRedirectUrl (params) {
  return request.request({
    url: '/api/cashier/payTypeOrder',
    method: 'POST',
    data: {...config.params, ...params,environmentalType: wayCode.getPayWay().wayCode,token: config.cacheToken}
  })
}
// /** openid **/
export function getChannelUserId (params) {
  return request.request({
    url: '/api/cashier/channelUserId',
    method: 'POST',
    data:{...config.params, ...params,wayCode: wayCode.getPayWay().wayCode, token: config.cacheToken} 
    // data: Object.assign({ wayCode: wayCode.getPayWay().wayCode, token: config.cacheToken,...config.params }, params)
  })
}
// 支付
export function payParams (params) {
  return request.request({
    url: '/api/cashier/pay',
    method: 'POST',
    data:{agentNo:config.params.agentNo, ...params,channelUserId: channelUserIdUtil.getChannelUserId(),token: config.cacheToken} 
  })
}
// 查询
export function cacheQuery (data) {
  return request.request({
    url: '/api/pay/cache_query',
    method: 'POST',
    data: {...data,agentNo:config.params.agentNo,token: config.cacheToken }
  })
}

// 获取代理商
export function getAgentList (data) {
  return request.request({
    url: '/api/plug/getAgentList',
    method: 'POST',
    data:null
  })
}
export function getMchList (data) {
  return request.request({
    url: '/api/plug/getMchListByAgentNo',
    method: 'POST',
    data:data
  })
}
export function getPayWay (data) {
  return request.request({
    url: '/api/plug/getPayWayListByMchNo',
    method: 'POST',
    data:data
  })
}
export function getMchAppList (data) {
  return request.request({
    url: '/api/plug/getMchAppList',
    method: 'POST',
    data:data
  })
}
export function getOrderInfo (data) {
  return request.request({
    url: '/api/pay/query',
    method: 'POST',
    data:data
  })
}