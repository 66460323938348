

import md5 from 'md5'
class Sign {
  constructor() {
    this.version = "1.0"
    this.reqTime = new Date().getTime()
    this.appSecret = {
      'zp17h5ys': 'MIICdQIBADANBgkqhkiG9w0BAQEFAASCAl8wggJbAgEAAoGBAJBJ0x6DABTkfWH0YQ6J5izOhzbp2cPCHd5eQ4pUHc5id5r3tiLae/kV+klEko91MhCNBizEVMrBebefFdq7JsVnpKDAlyHwkeac3Cu8bVJwoeoJiw6BsdUwqgnFl8lwIua52PpG7mV+ixQPlhtGMn3zq1NlE4nHUze8DbSMQgwFAgMBAAECgYAr9THKc+KMeufv9cGz6jMjp2/uPORJDVSIMHOnKuVBwSHrJlho2eM3CYkM1UVJS00LWcttzAqBEtyAIolH8AhpWOwb6yudx3cJcgsU7ZrlAiZuBxB5CP45lj71ch+JjZMhcBTpYq2erqolpRpgO8x/TZNQKJ9BHyy4Fci4m5m0AQJBAOKqcKuHGogQX+h1FMKitJ25Uv1XK2yd+oIVScE1S0PI1EGiW5MC0UhHSbxnTgFLSa2rCeQy19d3ijq2/O417IUCQQCi9i04LLZCDkWvMLZ+6N46KCiBLq4cCVU6Bh/VV370NZF+Djz0ZqaYQ5TKbN4qgarFhNEOaYhWaKz8ZFrIj3mBAkABrzCNJcDdt/luechAtgLPlTv3o/kvonDlHJFivSmlBE4HetRhYEj714PFwaLDwbJ3dujo6DPQZxQA4bE5d+8dAkAgBZvRJAgUZtWOnVXfDkdMykNbTx7rZ3IV2so6k9zgg/449PdJVzp5RR5hwI7Pb3dtJLIn0Nj3kc4cMmQccQUBAkBn3yx+2tPVswsWzKeZ/sdqFy8uOJ8U4lTJe/GEEnlyAv6fUcFeEbCK3M0DHUHffLla5YC3vgPO8PIr4v7VXbvs',
      'A1725935245': 'MIICdwIBADANBgkqhkiG9w0BAQEFAASCAmEwggJdAgEAAoGBAPnvyRzHsdPymR+JV6TT3ETs4AbvOSqIXrfDVTtLIdH7ElzQT1gc033BKJ7561EDFjgZ7+Izv3/DLIS+2lsXzGSjRHalUBZ3Zc4HJP3ViFeYseIFdgycYvAFHfiq3hUhUaNlwdOjpDZ+zZ7f7nRpfIhR0/ko9OQeezzcFebgwm23AgMBAAECgYAEtMEiq5frl7vOXjWqQtr46UTmnIaFCIBQEXt/XFDMtV+JI9r6m8BXs9Vh+O63XcmDUOm1ZqgKbdBex4ExCeetS+cbB0RwVzdtvwAuXo00KJDp1ew5IXkAjPSt5+Cihz2MVDTw6olMdR9Xu+Y2jwa52cbckq6ynTn2zA67wr2xcQJBAP0Txrs3gb/gtsnxwRVTWzUVPbmM0cI83HlTWMF5EoJfrZJIodYkZw+g+dUe5JVatIkzMEaeZ3DtXrWwjRD76H0CQQD80rlhSTTxp75b6yxq0uts7rX215XH3XZNtvHy00rXW2/cVoYjV1uWN6tsU2iR5RGASSp7l1vqbRW+WcVh7/lDAkEA2Tvjh4qi3lCGtpvE8IhHry4XeLFu0DsryR0I/7HseGqmc/3SpR1ENf8o1I6bCbT9aRO+aa/I/P9UH8N3o0+IhQJBAMO7mM3a0WRti5C6R48EcEPLo5DRYyJzME4GXtayAnUsUzPwwLTHwx5PRyX8hS6+e4goHP3GNuENRxIxigDCuuUCQHVTPmhI9VF8mqI8udUK7bsdFmFgrZ2miAvQRy4dXQFZ7b7HiPhmWPBbYQTZQ/vFkTu1ZdRW6P4g29wLJEejphs=',
      "A1727585065":"MIICdgIBADANBgkqhkiG9w0BAQEFAASCAmAwggJcAgEAAoGBAJ3fFcq0PxN70u/tew3sAyWj0Be40yYdoUt83edLd8ugn8Vp0eDrtrVY84Dj4IqNb7e4olRT1TOau/tvht7XVr69Ei/HMQuU30F/LRxlwgAyOyC/dzyatmJgQIcj4qiaNgaAJe0hcvHQW3+9LWkXfDg1zh0VRXFsK1HPy4Zx24lxAgMBAAECgYAwVZnVauQY8gM+BAZ117mK76GJccPr2oyEkSFbbEasKGxiQ6jHZUOWODqtTpdztCPtKvFAQj53Qzukmpu/ZEyhM40P2RG6pD8ATF1YVTJO7txU87k2mBPrOKwD3MB2ElAQ5bAO89I5x6JRl6JQU/lxDyufSiFjlDJAQFULlrRndQJBAN3aP7Ls3r0QEgXpnxgD1Iiu3XpoU9FSbL3pnSu/SgIokRpKP0qLiWnwW+KmyuUZKqP+x+K9pneTayyjk6psCGMCQQC2K8LV8j5ZGJqW9RENqUvCt5SURhyIFfMz1bbWB8zyBiUwKHOmOlSjjmBRIWEkwEDwvMntqfoDo0ng51CLce0bAkEAlfZNUJklBw+x3yVIUWdlZu8s7u1OmxPoRYeAom2vjT32t5qwpackv+OleNPeGnakiC5U10PzHqkbrRT1YCGemQJAIrny2S/lfLx485/h0VXdcE/ojd9Tnwl4JABwaxpvkg9j1WtQqHZkHg2NBMo1J3lSp9GFOL33P+xHsAzISNy90wJAPQhF0ixeiGQC+XPF4WTlPbM6JCxlkfB7vcg7aQ4M5H9cQbMvO7ovpeBsLCI21zH+lmeqAAJLwA2tk6zlvTB1Fg==",
      "A1727610711":"MIICdwIBADANBgkqhkiG9w0BAQEFAASCAmEwggJdAgEAAoGBANvErXfF6KJd5Gs7w77I0UU+NmVSGExpWzAHJ2l1zdeGzyc6NDUUc7I6oYY9YeMYL0qjGrOjLvJp+p0gzGrxr5oHALmyhB+CJiYxp+b8y3qbiVKe2wVSzQ67opfEiY4hrsyB2ynbXPydNmfAe3zdE4jB2iErdJmz76GjXHhl+hl5AgMBAAECgYBtd+PiT8oHqpY/Vec0jqozAACvanuhAC/EViPrE5uDWmqwQv1DXVsd8beIeuXZ7S+NUX5Y8ZVOeby0bOR19so9FgbPmkhi+dY45+mn9Egue/xGNcpFxskueCLc+1wJsFO7pxa4fdIXJB/CFJqNR8DT6+4jswXNcSBwy/h4uPZ5iwJBAPU2nanuqlqJjoz4PdE575KTwlgvPBumHjw7GyNW3epIf7oode0HpWARO8YYrzxMucB5hUA45iNyBWe7t3xBfQ8CQQDlb4VfB+MLMMYhRL1GFtwh3xUxyavqxFLWpwCtKC/88w+iDqN5X1Pzi1wqJ6DD4XZfWCZ/nd1Y8kDttgoQbpD3AkEA4bZNOE9k5YO7AI28MSvYFx8FhDCubnDhYeWyHDxodrAXSh7WNcGU+zHByJ2OJ74kOURiYHQ/ep8I1KxYv/p0BwJBAMae/8fgDQagB7W/S1fE7HcKJ8/9Na48nTYkRyqgEsMZLFxv7cdh5kx9RaFcwwkwQwiRSGrMIFYjCmB/akEFZssCQG9+eBVbgtXzJKzEHDk9DQLlYiFzihUTU+FOpv8KlSuP/g7G+DzCx+PfCitGO08/6prSSvlFb++PXgVARl55GfU=",
      'A1730168163':'MIICeAIBADANBgkqhkiG9w0BAQEFAASCAmIwggJeAgEAAoGBALh7TF6gzZL6YFZIxC9NQQ4JhrBCcHKOHIYdLkX4BKuwfiJ9GSH7IuTa2cSZZCxi7U48f64kJlCpN89tlKj0iAysiZSc4qtGjy1iEGO+ssKTjB6VLI9l5u3nOEpIVD9avbMMKPjKrjl18pvONYjg7hht84dRfubzUyBG2W5cz8p9AgMBAAECgYAtPrYt/ie1DLmcZ9fP1xVltTHmoEDWY6K8xMLgz7GLjyt8V2yW0DJQaRlgNZOjLDcRyVYyNjT8cugYdEJxxploATrgKM8y91+NU1+VLbvfIuO7l9kqmM6l+Bpd7at9TC3HlwtyN27WcwjCyuGZl32RkN0RXL3v9GpCGr3s992AwQJBAO/DJRaORmF+XmbwGbiuZo1/Sm/y83gihMst7UKqUgF/A8N1Aa2ZvCzOAa4+L1oWvEFe9TgjIi30tic7AO9VskECQQDE+bsHMaggNu5Jbdps46a6vKwEDYD/GpuH2oCB0A6eG72JnP2DpEHQ5u9lbg8Fuc1uxnFZGQ4OzRyH3n2lDhE9AkEA44krwlslOdLGXiRQFK8VzgTWLKXBHuqLYZLlTlX620ah2LVK8WgrZ83dVHHZWEKpR9SJHUxiXICW+WYKfheywQJBALwxeV6zM9WrhdkEKvd0Pb0WPCcXG9MgAc83lSXxubWX/Wv4YUKf8f38VmeLUrD+x2b6FBuzU3oSwBqUGWlUWmUCQQCBl+QxqMMTPFjg3PWiJGN/FBqlO/VYJuYKvgQY9HaORo2IjIs84hX6GA0YVUtrjK1ErfgoYisTgBDb7MrgsCGQ'
    }
    this.signType = 'MD5'
  }


  createSign(params) {
    const signObj = { ...params,reqTime: this.reqTime, version: this.version,signType:this.signType}
    let signObjSort = {}
    Object.keys(signObj).sort().forEach((key) => {
      signObjSort[key] = signObj[key];
    });
    let signObjStr = ''
    for (const key in signObjSort) {
      if(signObjSort[key]){
        signObjStr += `${key}=${signObjSort[key]}&`
      }
    }
    // console.log(signObjStr)
    const str=signObjStr+`key=${this.appSecret[params?.agentNo||'']}`
    // console.log(str,'signObjStr')
    const signStr = md5(str).toUpperCase()
    return signStr
  }
}

export default Sign
